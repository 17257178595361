<!-- Header.vue -->
<template>
  <div v-if="isInitialized" class="w-full">
    <div>
      <h1 class="text-xl md:text-2xl font-sans text-white p-3 md:p-5 text-center">
        ROUNDS TOP 5
      </h1>
    </div>
    <div class="scores-container">
      <!-- Table Header -->
      <div class="grid grid-cols-12 gap-2 text-gray-400 text-sm mb-2 px-4">
        <div class="col-span-2">Rank</div>
        <div class="col-span-3">Player</div>
        <div class="col-span-2 text-center">Status</div>
        <div class="col-span-2 text-right">Score</div>
        <div class="col-span-3 text-right">Potential Reward</div>
      </div>

      <!-- Table Rows -->
      <div
        v-for="(score, index) in currentRoundTopScores"
        :key="index"
        class="border-2 mb-2 hover:border-gray-500 transition-colors duration-200"
      >
        <div class="grid grid-cols-12 gap-2 items-center px-4 py-2">
          <!-- Rank -->
          <div class="col-span-2 flex items-center">
            <span class="text-lg font-bold text-gray-400">{{ index + 1 }}</span>
          </div>

          <!-- Player Address -->
          <div class="col-span-3 flex items-center">
            <span class="text-sm md:text-base">{{ formatAddress(score.web3Address) }}</span>
          </div>

          <!-- Submission Status -->
          <div class="col-span-2 text-center">
            <span
              :class="score.isSubmitted ? 'text-green-500' : 'text-yellow-500'"
              :title="score.isSubmitted ? 'Score submitted' : 'Pending submission'"
            >
              {{ score.isSubmitted ? "✓" : "⌛" }}
            </span>
          </div>

          <!-- Score -->
          <div class="col-span-2 text-center">
            <span class="text-sm md:text-base">{{ score.score }}</span>
          </div>

          <!-- Potential Reward -->
          <div class="col-span-3 text-right">
            <span class="text-sm md:text-base">{{ calculatePotentialReward(score) }} $Flappy</span>
            <span class="text-xs text-gray-400 block">
              ({{ score.potentialAllocationPercentage }}%)
            </span>
          </div>
        </div>
      </div>

      <!-- Total Players -->
      <div class="mt-4 text-center text-gray-400 text-sm md:text-base">
        {{ roundParticipants }} total players this round
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <span class="text-white">Loading...</span>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "StatsDisplay",
  computed: {
    ...mapState({
      web3: (state) => state.web3,
      web3Address: (state) => state.web3Address,
      GameContract: (state) => state.GameContract,
      roundParticipants: (state) => state.roundParticipants,
      currentRoundTopScores: (state) => state.currentRoundTopScores,
      prizePool: (state) => state.prizePool,
    }),
    isInitialized() {
      return this.web3 && this.web3Address && this.GameContract;
    },
  },
  methods: {
    ...mapActions(["fetchRoundParticipants", "fetchCurrentRoundTopScores"]),
    formatAddress(address) {
      return `${address.substring(0, 4)}...${address.substring(
        address.length - 4
      )}`;
    },
    calculatePotentialReward(score) {
      if (!this.prizePool || !score.potentialAllocationPercentage) return "0.00";
      const percentage = parseFloat(score.potentialAllocationPercentage) / 100;
      return (this.prizePool * percentage).toFixed(2);
    },
  },
  async mounted() {
    if (this.isInitialized) {
      await Promise.all([
        this.fetchRoundParticipants(),
        this.fetchCurrentRoundTopScores(),
      ]);
    }
  },
  watch: {
    isInitialized(newVal) {
      if (newVal) {
        this.fetchRoundParticipants();
        this.fetchCurrentRoundTopScores();
      }
    },
  },
};
</script>

<style scoped>
.scores-container {
  @apply bg-opacity-20 bg-gray-800 rounded-lg p-4;
}

.border-2 {
  @apply border-gray-700 bg-opacity-30 bg-gray-800 rounded-lg;
}

/* Add hover effect for rows */
.border-2:hover {
  @apply bg-opacity-40;
}

/* Optional: Add animation for status icons */
.text-green-500, .text-yellow-500 {
  @apply transition-all duration-300;
}

.text-yellow-500 {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
</style>
