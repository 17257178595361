<template>
  <AppNavigation></AppNavigation>
  <div v-if="isLoading" class="loading-screen">
    <div class="pixel-runner"></div>
    <div class="progress-bar">
      <div class="progress" :style="{ width: loadingProgress + '%' }"></div>
    </div>
    <p>{{ loadingMessage }}</p>
  </div>

  <div v-else>
    {{ debugRequirements }}
    <div
      class="flex sm:mt-28 md:mt-14 lg:mt-14 flex-wrap gap-4 bg-screen-grey old-tv container-above bg-gradient-to-t from-cyan-100 from-0% via-cyan-200 to-cyan-600 to-100%"
    >
      <div
        v-if="!canPlayGame && gameState == 1 && web3Address != null"
        class="requirements-message basis-full flex place-content-center flex-row flex-wrap bg-contain bg-center bg-no-repeat !border min-h-[525px] bg-gradient-to-b from-orange-400 from-0% via-orange-300 to-orange-700 to-100%"
      >
        <GameRequirements />
      </div>
      <div
        v-else
        id="container"
        class="basis-full flex place-content-center flex-col bg-contain bg-center bg-no-repeat !border min-h-[525px] bg-gradient-to-b from-orange-400 from-0% via-orange-300 to-orange-700 to-100%"
      >
        <GameComponent></GameComponent>
      </div>
      <div
        v-if="isInitialized && gameState == 1 && web3Address != null"
        class="leaderboard bg-neutral-900 md:basis-1/2-gap-4 sm:basis-full !border-2 min-h-[30px] py-4 px-8"
      >
        <GamesLeft></GamesLeft>
      </div>
      <div
        v-if="isInitialized"
        class="leaderboard bg-neutral-900 md:basis-1/2-gap-4 sm:basis-full !border-2 min-h-[30px] py-4 px-8"
      >
        <RemainingTimeDisplay />
      </div>
      <div
        v-if="isInitialized && gameState == 1"
        class="leaderboard bg-neutral-900 md:basis-1/2-gap-4 sm:basis-full !border-2 min-h-[30px] py-4 px-8"
      >
        <AirdropAllocation></AirdropAllocation>
      </div>

      <div
        v-if="isInitialized"
        class="rightbuttonarea bg-neutral-900 md:basis-1/2-gap-4 sm:basis-full !border-2 min-h-[100px] p-8"
      >
        <RightButtonArea></RightButtonArea>
      </div>
      <div
        v-if="isInitialized"
        class="leaderboard bg-neutral-900 md:basis-1/2-gap-4 sm:basis-full !border-2 min-h-[100px] p-8"
      >
        <LeaderBoard></LeaderBoard>
      </div>
      <div
        v-if="isInitialized"
        class="leaderboard bg-neutral-900 md:basis-1/2-gap-4 sm:basis-full !border-2 min-h-[100px] p-8"
      >
        <StatsDisplay></StatsDisplay>
      </div>
    </div>
    <div class="flex flex-row bg-custom-orange">
      <div class="basis-1/4"></div>
      <div class="basis-1/4 image-container min-h-[150px]">
        <img
          src="../public/assets/gear-img.png"
          alt=""
          class="float-right pr-24"
        />
      </div>
      <div class="basis-1/4 image-container min-h-[150px]">
        <img
          src="../public/assets/gear-img2.png"
          alt=""
          class="float-left pr-24"
        />
      </div>
      <div class="basis-1/4"></div>
    </div>
    <div class="flex flex-wrap gap-3">
      <div
        class="flex-grow !border-2 full-width min-w-[800px] p-24 bg-footer-texture"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import GameComponent from "@/components/GameComponent.vue";
import AppNavigation from "@/components/AppNavigation.vue";
import LeaderBoard from "@/components/LeaderBoard.vue";
import RightButtonArea from "@/components/RightButtonArea.vue";
import GamesLeft from "@/components/GamesLeft.vue";
import AirdropAllocation from "@/components/AirdropAllocation.vue";
import RemainingTimeDisplay from "@/components/RemainingTimeDisplay.vue";
import StatsDisplay from "@/components/StatsDisplay.vue";
import GameRequirements from "@/components/GameRequirements.vue";
import { useUserSessionManager } from "./assets/js/userSessionManager";

const { lastActivity } = useUserSessionManager();
console.log(lastActivity);

export default {
  name: "App",
  components: {
    GameComponent,
    AppNavigation,
    LeaderBoard,
    RightButtonArea,
    GamesLeft,
    AirdropAllocation,
    RemainingTimeDisplay,
    StatsDisplay,
    GameRequirements,
  },
  data() {
    return {
      isLoading: false,
      loadingProgress: 0,
      loadingMessage: "Connecting to the game world...",
    };
  },
  computed: {
    ...mapState([
      "isInitialized",
      "web3Address",
      "isWalletConnected",
      "gameState",
    ]),
    ...mapGetters(["canPlayGame"]),
    debugRequirements() {
      console.log("Requirements check:", {
        canPlayGame: this.canPlayGame,
        gameState: this.gameState,
        web3Address: this.web3Address,
        shouldShowRequirements:
          !this.canPlayGame && this.gameState == 1 && this.web3Address != null,
      });
      return null;
    },
  },
  watch: {
    web3Address(newVal) {
      if (newVal) {
        this.startLoading();
      } else {
        this.isLoading = false;
      }
    },
    isInitialized(newVal) {
      if (newVal) {
        this.stopLoading();
      }
    },
  },
  created() {
    this.loadGameScripts();
  },
  methods: {
    loadScript(src) {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          resolve();
          return;
        }
        const script = document.createElement("script");
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    async loadGameScripts() {
      try {
        await this.loadScript("js/jquery.min.js");
        await this.loadScript("js/jquery.transit.min.js");
        await this.loadScript("js/buzz.min.js");
        await this.loadScript("js/main.js");
        console.log("All game scripts loaded successfully");
        this.$store.commit("SET_GAME_SCRIPTS_LOADED", true);
        // You might want to set a flag or emit an event here
      } catch (error) {
        console.error("Error loading game scripts:", error);
      }
    },
    startLoading() {
      this.isLoading = true;
      this.loadingProgress = 0;
      this.updateLoadingProgress();
    },
    stopLoading() {
      this.loadingProgress = 100;
      setTimeout(() => {
        this.isLoading = false;
      }, 500); // Short delay to show 100% before hiding
    },
    updateLoadingProgress() {
      const interval = setInterval(() => {
        if (this.loadingProgress < 90) {
          this.loadingProgress += Math.random() * 10;
          this.updateLoadingMessage();
        } else if (!this.isInitialized) {
          this.loadingProgress = 90; // Stay at 90% until initialized
        } else {
          clearInterval(interval);
          this.stopLoading();
        }
      }, 200);
    },
    updateLoadingMessage() {
      const messages = [
        "Powering up the game engine...",
        "Generating virtual world...",
        "Calibrating pixel physics...",
        "Tuning retro soundscape...",
        "Preparing web3 features...",
      ];
      this.loadingMessage = messages[Math.floor(this.loadingProgress / 20)];
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
.old-tv {
  position: relative;
  padding: 2rem;
  border-radius: 1px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6), inset 0 0 8px rgba(255, 255, 255, 0.2);
}

.old-tv::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 1px;
  pointer-events: none;
}

.container-above {
  position: relative;
  z-index: 0;
}

.image-container {
  position: relative;
  margin-top: -50px;
  z-index: 1;
}

.image-container img {
  display: block;
  width: 95%;
  position: absolute;
  left: 10%;
  top: 0;
}
.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000;
  color: #fff;
  font-family: "VT323", monospace;
  text-align: center;
}

.pixel-runner {
  width: 64px;
  height: 64px;
  background-image: url("./assets/spriteLoading.png");
  animation: run 0.5s steps(8) infinite;
}

@keyframes run {
  from {
    background-position: 0px;
  }
  to {
    background-position: -512px;
  }
}

.progress-bar {
  width: 200px;
  height: 20px;
  background: #333;
  margin: 20px 0;
  border: 2px solid #fff;
}

.progress {
  height: 100%;
  background: #0f0;
  transition: width 0.2s ease-out;
}

p {
  font-size: 16px;
  margin-top: 20px;
}
</style>

