<template>
  <div class="game-requirements">
    <h1 class="text-2xl font-bold text-center mb-6 text-white">
      🎮 Ready to Play? 
    </h1>

    <!-- Prize Pool Display -->
    <div class="prize-pool-section mb-8">
      <div class="prize-pool-container">
        <div class="prize-pool-header">
          <span class="trophy-icon">🏆</span>
          <h2 class="text-xl text-white mb-2">Current Prize Pool</h2>
          <span class="trophy-icon">🏆</span>
        </div>
        
        <div class="prize-amount-wrapper">
          <div class="prize-amount-container">
            <div class="prize-sparkles left">✨</div>
            <div class="prize-amount">
              {{ Number(prizePool).toFixed(2) }} $Flappy
            </div>
            <div class="prize-sparkles right">✨</div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="requirements-container">
      <!-- Token Requirement -->
      <div class="requirement-card" :class="{ 'requirement-met': hasEnoughTokens }">
        <div class="requirement-header">
          <span class="text-xl">Step 1</span>
          <span class="status-badge" :class="{ 'status-complete': hasEnoughTokens }">
            {{ hasEnoughTokens ? "✓ Complete" : "⚠ Required" }}
          </span>
        </div>
        <div class="requirement-content">
          <h3 class="text-lg mb-2">Hold $Flappy</h3>
          <p class="text-sm text-gray-300 mb-4">
            Hold at least 2000 $Flappy tokens to participate in the game
          </p>
          <div class="card-footer">
            <a v-if="!hasEnoughTokens" 
               :href="buyTokenUrl" 
               target="_blank" 
               class="btn-action"
            >
              Get $Flappy Tokens
            </a>
          </div>
        </div>
      </div>

      <!-- Entry Fee Requirement -->
      <div class="requirement-card" :class="{ 'requirement-met': hasEntryFee }">
        <div class="requirement-header">
          <span class="text-xl">Step 2</span>
          <span class="status-badge" :class="{ 'status-complete': hasEntryFee }">
            {{ hasEntryFee ? "✓ Complete" : "⚠ Required" }}
          </span>
        </div>
        <div class="requirement-content">
          <h3 class="text-lg mb-2">Unlock Round</h3>
          <p class="text-sm text-gray-300 mb-4">
            Unlock this round for 10 $Flappy and compete for the prize pool!
          </p>
          <div class="card-footer">
            <button
              v-if="!hasEntryFee"
              @click="payEntryFee"
              :disabled="!canPayEntryFee"
              class="btn-action"
              :class="{ 'btn-disabled': !canPayEntryFee }"
            >
              Unlock Round Now
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Action Section -->
    <div class="action-section">
      <div v-if="!canPlayGame" class="warning-card">
        <span class="text-yellow-400">⚠️</span>
        <p>Complete both steps to start playing!</p>
      </div>
      <button 
        v-else 
        @click="refreshPage"
        class="btn-play animate-bounce"
      >
        🚀 Let's Play!
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "GameRequirements",
  data() {
    return {
      buyTokenUrl: "https://app.uniswap.org/swap?chain=arbitrum",
    };
  },
  computed: {
    ...mapState(["userBalance", "hasEntryFee", "prizePool"]),
    ...mapGetters(["canPlayGame"]),
    hasEnoughTokens() {
      return parseFloat(this.userBalance) >= 2000;
    },
    canPayEntryFee() {
      return parseFloat(this.userBalance) >= 10;
    },
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    async payEntryFee() {
      try {
        await this.$store.dispatch("payEntryFee");
      } catch (error) {
        console.error("Failed to pay entry fee:", error);
      }
    },
  },
};
</script>

<style scoped>
.game-requirements {
  @apply max-w-4xl mx-auto p-4;
}

.requirements-container {
  @apply grid grid-cols-1 md:grid-cols-2 gap-6;
}

.requirement-card {
  @apply bg-gray-800 rounded-lg p-6 border-2 border-gray-700 transition-all duration-300;
}

.requirement-card:hover {
  @apply transform -translate-y-1 shadow-lg border-gray-500;
}

.requirement-met {
  @apply bg-gradient-to-br from-green-900 to-green-800 border-green-500;
}

.requirement-header {
  @apply flex justify-between items-center mb-4 text-white;
}

.status-badge {
  @apply text-2xl animate-pulse;
}

.btn-action {
  @apply bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg 
         transition-colors duration-300 inline-block text-center;
}

.btn-play {
  @apply bg-gradient-to-r from-green-500 to-blue-500 text-white font-bold py-3 px-8 
         rounded-lg text-xl shadow-lg hover:shadow-xl transition-all duration-300;
}

.prize-pool-display {
  @apply text-center p-3 bg-gray-900 rounded-lg text-yellow-400 font-bold mt-2;
}

.warning-card {
  @apply flex items-center justify-center space-x-2 bg-gray-900 p-4 rounded-lg 
         text-white text-center mt-6;
}

.action-section {
  @apply mt-8 text-center;
}

.prize-pool-section {
  @apply text-center p-6 rounded-lg;
}

.prize-amount {
  @apply text-4xl font-bold;
  @apply bg-gradient-to-r from-yellow-400 via-yellow-200 to-yellow-400 bg-clip-text text-transparent;
  @apply animate-pulse;
}

.prize-pool-container {
  @apply relative overflow-hidden rounded-xl;
  background: linear-gradient(
    135deg,
    rgba(17, 24, 39, 0.95) 0%,
    rgba(55, 65, 81, 0.95) 100%
  );
  box-shadow: 0 0 30px rgba(59, 130, 246, 0.3);
}

.prize-pool-header {
  @apply flex items-center justify-center gap-4 py-3;
  background: rgba(0, 0, 0, 0.2);
}

.trophy-icon {
  @apply text-2xl animate-bounce;
  animation-duration: 2s;
}

.prize-amount-wrapper {
  @apply py-8 px-12 relative;
  background: radial-gradient(circle at center, rgba(59, 130, 246, 0.2) 0%, transparent 70%);
}

.prize-amount-container {
  @apply relative flex justify-center items-center;
}

.prize-amount {
  @apply text-5xl font-bold py-4 px-8 rounded-lg;
  background: linear-gradient(to right, #60a5fa, #93c5fd, #60a5fa);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0 0 20px rgba(96, 165, 250, 0.5);
  animation: glow 2s ease-in-out infinite;
}

.prize-sparkles {
  @apply absolute text-2xl;
  animation: sparkle 1.5s ease-in-out infinite;
}

.prize-sparkles.left {
  left: -20px;
}

.prize-sparkles.right {
  right: -20px;
}

@keyframes glow {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.8; }
}

@keyframes sparkle {
  0%, 100% { 
    transform: scale(1);
    opacity: 1;
  }
  50% { 
    transform: scale(1.2);
    opacity: 0.7;
  }
}
</style>
