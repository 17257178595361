<!-- LeaderBoard.vue -->
<template>
  <div v-if="isInitialized" class="w-full">
    <div>
      <h1 class="text-xl md:text-2xl font-sans text-white p-3 md:p-5 text-center">
        ALL TIME TOP 10
      </h1>
    </div>
    <div class="scores-container">
      <!-- Table Header -->
      <div class="grid grid-cols-12 gap-2 text-gray-400 text-sm mb-2 px-4">
        <div class="col-span-2">Rank</div>
        <div class="col-span-6">Player</div>
        <div class="col-span-4 text-right">Score</div>
      </div>

      <!-- Table Rows -->
      <div
        v-for="(entry, index) in leaderboard"
        :key="index"
        class="border-2 mb-2 hover:border-gray-500 transition-colors duration-200"
      >
        <div class="grid grid-cols-12 gap-2 items-center px-4 py-2">
          <!-- Rank -->
          <div class="col-span-2 flex items-center">
            <span class="text-lg font-bold text-gray-400">{{ index + 1 }}</span>
          </div>

          <!-- Player Address -->
          <div class="col-span-6 flex items-center">
            <span class="text-sm md:text-base">{{ formatAddress(entry.web3Address) }}</span>
          </div>

          <!-- Score -->
          <div class="col-span-4 text-right">
            <span class="text-sm md:text-base">{{ entry.bestScoreEver }} FUD dodged</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <span class="text-white">Loading...</span>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LeaderBoard",
  computed: {
    ...mapState({
      leaderboard: (state) => state.leaderboard,
      web3: (state) => state.web3,
      web3Address: (state) => state.web3Address,
      GameContract: (state) => state.GameContract,
    }),
    isInitialized() {
      return this.web3 && this.web3Address && this.GameContract;
    },
  },
  methods: {
    formatAddress(address) {
      return `${address.substring(0, 4)}...${address.substring(
        address.length - 4
      )}`;
    },
  },
};
</script>

<style scoped>
.scores-container {
  @apply bg-opacity-20 bg-gray-800 rounded-lg p-4;
}

.border-2 {
  @apply border-gray-700 bg-opacity-30 bg-gray-800 rounded-lg;
}

.border-2:hover {
  @apply bg-opacity-40;
}
</style>
