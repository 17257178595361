const gameABI = require("./abis/game.json");
const tokenABI = require("./abis/token.json");

const contractConfig = {
  local: {
    gameContractAddress: process.env.VUE_APP_GAME_CONTRACT,
    tokenContractAddress: process.env.VUE_APP_TOKEN_CONTRACT,
    gameAbi: gameABI,
    tokenAbi: tokenABI,
  },
  testnet_arb: {
    gameContractAddress: process.env.VUE_APP_GAME_CONTRACT,
    tokenContractAddress: process.env.VUE_APP_TOKEN_CONTRACT,
    gameAbi: gameABI,
    tokenAbi: tokenABI,
  },
  mainnet_arb: {
    gameContractAddress: process.env.VUE_APP_GAME_CONTRACT,
    tokenContractAddress: process.env.VUE_APP_TOKEN_CONTRACT,
    gameAbi: gameABI,
    tokenAbi: tokenABI,
  },
  blastSepolia: {
    gameContractAddress: process.env.VUE_APP_BLAST_SEPOLIA_GAME_CONTRACT,
    tokenContractAddress: process.env.VUE_APP_BLAST_SEPOLIA_TOKEN_CONTRACT,
    gameAbi: gameABI,
    tokenAbi: tokenABI,
  },
};

export default contractConfig;
