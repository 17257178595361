<template>
  <div>
    <div class="relative flex items-center justify-center">
      <h1 class="text-2xl font-sans text-white p-5 text-center">
        Your Prize Share
        <i
          class="material-icons text-blue-500 cursor-pointer"
          v-tippy="{
            content:
              'Your allocation in the prize pool based on your score. Claimable after the current round ends.',
          }"
          >info</i
        >
      </h1>
    </div>
    <div v-if="isLoading" class="loading-container">
      <span class="loading-text">Loading</span>
    </div>
    <div v-else>
      <div v-if="displayedAllocation > 0">
        <div
          class="border-2 border-yellow-600 bg-yellow-100 mb-5 text-center text-black"
        >
          <p>{{ displayedAllocation }} $Flappy</p>
          <p v-if="gameState == 1" class="text-xs text-gray-600">(Estimated)</p>
        </div>
        <div
          v-if="gameState == 2"
          class="animate-pulse border-2 border-green-600 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% mb-5 text-center"
        >
          <button @click="claimAllocation">Claim</button>
        </div>
      </div>
      <div
        v-else
        class="border-2 border-red-600 bg-red-100 mb-5 text-center text-black"
      >
        <p>No allocation</p>
      </div>
    </div>
    <div class="mb-2 text-center text-xs">
      Based on your score of {{ score }}.
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import apiService from "../assets/js/apiService";
import { useToast } from "vue-toastification";

export default {
  name: "AirdropAllocation",
  setup() {
    console.log("AirdropAllocation setup started");
    const toast = useToast();
    const store = useStore();
    const isLoading = ref(true);
    const estimatedAllocation = ref(0);

    const gameState = computed(() => store.state.gameState);
    const score = computed(() => store.state.highestScore);
    const prizePool = computed(() => store.state.prizePool);
    const storeAllocation = computed(() => parseFloat(store.state.allocation));

    const displayedAllocation = computed(() => {
      return storeAllocation.value > 0
        ? storeAllocation.value
        : estimatedAllocation.value;
    });

    async function fetchEstimatedAllocation() {
      console.log("Fetching allocation...");
      isLoading.value = true;
      try {
        const responsePromise = apiService.getCurrentPotentialAllocation();
        const response = await responsePromise;
        const percentageAllocation = parseFloat(response);
        console.log("percentageAllocation", percentageAllocation);
        console.log(typeof percentageAllocation);
        if (isNaN(percentageAllocation)) {
          throw new Error("Invalid percentage allocation received");
        }

        const totalPrizePool = parseFloat(prizePool.value);
        console.log("totalPrizePool", totalPrizePool);

        if (isNaN(totalPrizePool)) {
          throw new Error("Invalid prize pool value");
        }

        // Calculate the actual allocation
        const actualAllocation = (percentageAllocation / 100) * totalPrizePool;
        estimatedAllocation.value = actualAllocation.toFixed(4);
        console.log("Estimated allocation set to:", estimatedAllocation.value);
      } catch (error) {
        console.error("Error in fetchEstimatedAllocation:", error);
        toast.error(`Error fetching allocation: ${error.message}`);
        estimatedAllocation.value = 0;
      } finally {
        isLoading.value = false;
        console.log("isLoading set to false");
      }
    }

    // Watch for changes in the highestScore
    watch(
      score,
      (newScore, oldScore) => {
        console.log(`Score changed from ${oldScore} to ${newScore}`);
        if (newScore !== null && newScore !== undefined) {
          fetchEstimatedAllocation();
        }
      },
      { immediate: true }
    );

    // Watch for changes in the prizePool
    watch(prizePool, (newPrizePool, oldPrizePool) => {
      console.log(`Prize pool changed from ${oldPrizePool} to ${newPrizePool}`);
      if (newPrizePool !== null && newPrizePool !== undefined) {
        fetchEstimatedAllocation();
      }
    });

    console.log("AirdropAllocation setup completed");

    return {
      isLoading,
      displayedAllocation,
      gameState,
      score,
    };
  },
};
</script>

<style scoped>
.loading-container {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 5px;
  overflow: hidden;
  border-radius: 5px;
}

.loading-text {
  color: white;
  font-weight: bold;
  z-index: 1;
  position: relative;
}

.loading-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, transparent, red, transparent);
  animation: knightRiderAnimation 2s infinite linear;
}

@keyframes knightRiderAnimation {
  0% {
    left: -100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

.material-icons {
  vertical-align: baseline;
}
</style>
