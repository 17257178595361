<template>
  <div class="text-center">
    <div>
      <h1 class="text-2xl font-sans text-white p-5">
        <span v-if="gameState === 0">Game Round awaiting funding</span>
        <span v-else-if="isTransitioningToClaimTime">Finalizing Round...</span>
        <span v-else>Round Ends in:</span>
      </h1>
    </div>
    <div v-if="isLoading" class="loading-container">
      <span class="loading-text">Loading</span>
    </div>
    <div v-else>
      <span v-if="gameState !== 0 && !isTransitioningToClaimTime">{{ formattedRemainingTime }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "RemainingTimeDisplay",
  data() {
    return {
      isLoading: true,
      intervalId: null,
    };
  },
  computed: {
    ...mapState({
      remainingTime: (state) => state.remainingTime,
      gameState: (state) => state.gameState,
      web3: (state) => state.web3,
      accounts: (state) => state.web3Address,
      isTransitioningToClaimTime: (state) => state.isTransitioningToClaimTime,
    }),
    formattedRemainingTime() {
      if (this.gameState === 0) {
        return "Game Round awaiting funding";
      } else if (this.remainingTime <= 0 && this.gameState === 1) {
        return "Round is over after next score submission";
      } else if (this.remainingTime <= 0 && this.gameState === 2) {
        return "Waiting for last claimer.";
      } else {
        const remainingSeconds = this.remainingTime;
        const hours = Math.floor(remainingSeconds / 3600);
        const minutes = Math.floor((remainingSeconds % 3600) / 60);
        const seconds = remainingSeconds % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchGameState",
      "fetchRemainingTime",
      "fetchClaimEndTime",
    ]),
    async updateRemainingTime() {
      this.isLoading = true;
      try {
        await this.fetchGameState();
        if (this.gameState === 1) {
          await this.fetchRemainingTime();
          this.startCountdown();
        } else if (this.gameState === 2) {
          await this.fetchClaimEndTime();
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
          this.intervalId = setInterval(() => {
            if (this.remainingTime > 0) {
              this.$store.commit("SET_REMAINING_TIME", this.remainingTime - 1);
            }
          }, 1000);
        }
      } catch (error) {
        console.error("Failed to update remaining time:", error);
      } finally {
        this.isLoading = false;
      }
    },
    startCountdown() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(async () => {
        if (this.remainingTime > 0) {
          this.$store.commit("SET_REMAINING_TIME", this.remainingTime - 1);
        } else {
          clearInterval(this.intervalId);
          this.$store.commit("SET_TRANSITIONING_TO_CLAIM_TIME", true);
          await this.pollForClaimState();
        }
      }, 1000);
    },
    async pollForClaimState() {
      const pollInterval = setInterval(async () => {
        await this.fetchGameState();
        if (this.gameState === 2) {
          clearInterval(pollInterval);
          this.$store.commit("SET_TRANSITIONING_TO_CLAIM_TIME", false);
          await this.fetchClaimEndTime();
        }
      }, 15000);
      setTimeout(() => {
        clearInterval(pollInterval);
        this.$store.commit("SET_TRANSITIONING_TO_CLAIM_TIME", false);
      }, 120000);
    }
  },
  watch: {
    web3(newVal) {
      if (newVal) {
        this.updateRemainingTime();
      }
    },
  },
  async mounted() {
    if (this.web3 && this.accounts) {
      await this.updateRemainingTime();
    }
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style scoped>
.loading-container {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 5px;
  overflow: hidden;
  border-radius: 5px;
}

.loading-text {
  color: white;
  font-weight: bold;
  z-index: 1;
  position: relative;
}

.loading-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, transparent, red, transparent);
  animation: knightRiderAnimation 2s infinite linear;
}

@keyframes knightRiderAnimation {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
</style>
